/* Sector: GoToTop Btn main classes */

.btnBody {
  width: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.btnCont {
  padding: 0.4rem 0.8rem;

  border-radius: 0.5rem;

  color: var(--text-color);
  background-color: var(--background-sub-color-2);

  box-shadow: var(--shadow-3);
}

.btnIcon {
  width: 2.5rem;
  height: 2.5rem;

  cursor: pointer;

  color: var(--color-wht);
}
