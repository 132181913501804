/* Sector: Footer main styles */

.footerBody {
  width: 100%;
  height: auto;

  opacity: 0;

  padding: 1.5rem 0;
  margin-top: auto;

  border-top: 1px solid var(--border-color);

  display: grid;
  place-items: center;
}

.footerCont {
  max-width: 90rem;
  width: 70%;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.footerLogoCont {
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.footerLogoCont img {
  width: 6rem;
  height: auto;
}

.followingDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.followingDetails h4 {
  font-size: 1.6rem;
}

.footerIconsCont {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.footerIconCont {
  padding: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.footerIcon {
  width: 2rem;
  height: auto;

  cursor: pointer;

  color: var(--text-color);

  transition: all 0.1s ease-in-out;
}

.footerIcon:hover {
  color: var(--color-te);
}

/* Sector: Animations */

@keyframes move-down {
  0% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animationClass {
  animation: fade-in 2s ease-in-out 1s forwards;
}
