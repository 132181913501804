.msgContainer {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.msgContainer button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
  color: #000;
  background-color: transparent;
  border: none;
}

.msgContainer p {
  font-size: 1.2rem;
  color: #000;

  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.msgContainer p a {
  color: var(--color-se);
  text-decoration: underline;
}

.msgContainer p img {
  height: 1.5rem;
  width: 3rem;
  object-fit: cover;
}

/* Contentfull classes */
.boldTextContentful {
  font-weight: 600;
}

.italicTextContentful {
  text-decoration: none;

  font-style: italic;
  font-weight: 400;

  color: var(--text-color);
}

.underLineTextContentful {
  font-weight: 400;
  font-style: none;

  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 0.2px;

  color: var(--text-color);
}
