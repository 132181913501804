.App {
  width: 100%;
  min-height: 100vh;
  height: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

.goToTopBtn {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 900;
}
