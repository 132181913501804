/* Sector: Spinner main classes */

.spinnerContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  /* overflow-y: scroll; */

  background-color: var(--background-color);
}

.spinner {
  width: 4rem;
  height: auto;
}
