/* Sector: HireMeModal styles */

.modal {
  display: block;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
  height: 100%;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.hideModal {
  display: none;
}

.modalMain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  max-width: 800px;

  background-color: var(--background-color);

  border-radius: 4px;
}

.modalMain header {
  width: 100%;

  padding: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  border-bottom: 1px solid var(--border-color);
}

.modalMain header h3 {
  font-size: 2rem;
}

.closeIconCont,
.closeIconContLight {
  padding: 1rem;

  border-radius: 1.3rem;

  display: grid;
  place-items: center;
}

.closeIconCont {
  background-color: var(--color-pr-d1);
}

.closeIconContLight {
  background-color: var(--color-gr);
}

.closeIcon {
  width: 2.5rem;
  height: 2.5rem;

  cursor: pointer;
}

.modalMain form {
  padding: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.modalMain form input,
.modalMain form select,
.modalMain form textarea {
  width: 100%;

  padding: 1rem;

  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  outline: none;
  border-radius: 0.4rem;

  font-size: 1.6rem;
  font-family: inherit;

  color: var(--text-color);
}

.rtl {
  font-size: 1.4rem !important;

  direction: rtl;
  font-family: 'Readex Pro', sans-serif !important;
}

.modalMain form select {
  box-sizing: border-box;
}

.modalMain form input::placeholder,
.modalMain form textarea::placeholder {
  font-size: 1.6rem;

  color: var(--text-color);
}

.modalMain form button {
  padding: 1rem 2rem;

  border: none;
  border-radius: 0.5rem;

  background-color: var(--color-te);
  color: var(--color-wh);

  font-family: inherit;
  font-size: 1.4rem;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.modalMain form button:hover {
  background-color: var(--color-te-d1);
}

.error {
  color: #fe0000;

  font-size: 1.2rem;
}

/* Sector: media quires */

/* 590px / 16px = 36.875 */
@media (max-width: 37em) {
  .modalMain header h3 {
    font-size: 1.6rem;
  }

  .closeIconCont,
  .closeIconContLight {
    padding: 0.6rem;

    border-radius: 0.8rem;
  }

  .closeIcon {
    width: 2.2rem;
    height: 2.2rem;
  }
}
