/* Fonts */

/* English font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

/* Arabic font */
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;400;500;600;700&display=swap');

/* Main Styles */

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100%;

  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;

  background-color: var(--background-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Colors: */

  /* Primary color */
  --color-pr: #333333;
  --color-pr-d1: #2e2e2e;
  --color-pr-d2: #292929;
  --color-pr-l1: #474747;
  --color-pr-l2: #5c5c5c;

  /* Secondary color */
  --color-se: #38bdf8;
  --color-se-d1: #32aadf;
  --color-se-d2: #2d97c6;
  --color-se-l1: #4cc4f9;
  --color-se-l2: #60caf9;

  /* Tertiary color */
  --color-te: #6366f1;
  --color-te-d1: #4f46e5;
  --color-te-d2: #595cd9;
  --color-te-l1: #7375f2;
  --color-te-l2: #8285f4;

  /* Grey color */
  --color-gr: #eef2ff;
  --color-gr-d1: #d6dae6;
  --color-gr-d2: #bec2cc;
  --color-gr-d3: #a7a9b3;
  --color-gr-l1: #f0f3ff;
  --color-gr-l2: #f1f5ff;
  --color-gr-l3: #f3f6ff;

  --color-wh: #fff;
  --color-bl: #000;

  /* Shadows: */
  --shadow-1: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --shadow-2: 0px 5px 8px -6px rgba(0, 0, 0, 0.2);
  --shadow-3: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --shadow-4: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* a */
a {
  text-decoration: none;
  color: inherit;

  font-family: inherit;
}

a.active {
  background-color: transparent;
  color: var(--color-se);

  position: relative;
}

a.active:hover {
  background-color: transparent;
  color: var(--color-se) !important;
}

a.active::before {
  content: '';

  width: 100% !important;
  height: 3px;

  background-image: linear-gradient(
    to right,
    var(--background-color),
    var(--color-se)
  ) !important;

  position: absolute;
  bottom: -4px;
  left: 0;

  transition: all 0.5s ease-in-out;
}

.linksCont a.active::before {
  width: 100%;
}

a.active svg {
  color: var(--color-se);
}

/* Buttons */
button {
  font-family: inherit;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--color-wh);
  background: var(--color-se);
}

::selection {
  color: var(--color-wh);
  background: var(--color-se);
}
